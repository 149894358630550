import React from 'react';
import { Layout, Col } from 'antd';
import Responsive from '../utils/Responsive';
import styles from '../styles/components.module.css';

import TB1 from '../articles/TB1.png';
import TB2 from '../articles/TB2.png';
import TB3 from '../articles/TB3.png';
import TB4 from '../articles/TB4.png';
import TE1 from '../articles/TE1.png';

const Row = Responsive.ResponsiveRow;
const { Content } = Layout;

export default function TagEditorArticle() {
  return (
    <Content>
      <div className={styles.pageArticle}>
        <div className={styles.pageArticleContainer}>
          <h1>Tagbot, the simple and smart alternative</h1>
          <p>
            <b>Tagbot</b>, just like its colleague <b>TagEditor</b>, is an AWS
            tag management tool. <b>For only 19,99$</b> you will have the
            possibility to push much further -and much simpler- the management
            of your different tags.
          </p>
          <p>
            On Tagbot, we will appreciate the ease of the User Interface: a
            single page gathers all your information coupled with an artificial
            intelligence that suggests you the best tags to manage your AWS
            resources. Save time by letting Tagbot recommend up to date tags to
            keep your AWS cloud clean.
          </p>
          <p>
            In a few clicks, you will have access to a Dashboard gathering the
            tags of your different AWS resources.
          </p>
          <p>
            Effectively managing your tags will enable you to accurately manage,
            allocate and report your costs, find unused or orphaned resources,
            and implement AWS Best practices
            <br />
            <br />
            <span style={{ fontStyle: 'italic' }}>
              - Easy to use
              <br />
              - Update your tags using our AI-driven suggestions <br />-
              Managing has never been easier
            </span>
          </p>
          <p>
            From your first connection, you will have to create a link between
            your Tagbot account and your AWS account. At the top right of your
            screen, click on the <b>profile button</b>, and then click on{' '}
            <b>AWS Account</b>.<br />
            <br />{' '}
            <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
              - Nothing too complicated!
            </span>
            <br />
            <br />
            <span style={{ fontStyle: 'italic' }}>
              - Copy the ARN
              <br />- Click the "Launch stack" button that will launch an AWS
              cloud formation stack to create an IAM role.
            </span>
          </p>
          <img src={TB1} alt={'TB1'} />
          <p>
            Paste the ARN into the dedicated field, select « OK » and your
            resources will start synchronizing with Tagbot. It can take up to 1
            day to populate! And then, <b>everything is automatic!</b>
          </p>
          <img src={TB2} alt={'TB2'} />
          <p>
            Once the synchronization is complete, you can securely analyze your
            tag coverage over time, and start actively managing the tags on your
            services.
          </p>
          <img src={TB3} alt={'TB3'} />
          <p>
            <b>Tagbot</b> uses <b>artificial intelligence</b> to suggest the
            best tags for your services, saving time while keeping your
            resources up to date, the end result - <b>a clean AWS cloud!</b>
          </p>
          <p>
            Click on a <b>Suggestion</b> Button, and let <b>Tagbot</b> recommend
            the most appropriate tags for that service.
            <br />
            Then easily apply the tag or tags.
          </p>
          <img src={TB4} alt={'TB4'} />
          <p>
            <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
              - That’s all it takes!
            </span>
          </p>
          <p>
            <b>Tagbot</b> is THE solution for people who want to finally see a
            simple overview of their AWS resources. Easily check, manage and
            update your tags. Discover opportunities to rationalize your tagging
            to support reporting and your business requirements.
          </p>
          <p>
            <b>Tagbot</b> is the only simple, purpose-built tool to provide the
            visibility and easy remediation to ensure effective tagging in your
            AWS environment. It’s AI engine helps ensure your tags remain clean
            and consistent over time. Supporting your compliance, governance,
            cost allocation and infrastructure automation efforts. Tagbot’s
            dashboard provides a straightforward, easy-to-use consolidated view
            to chart your tagging coverage and progress over time.
          </p>
          <p>
            <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
              - So what are you waiting for to try it?!
            </span>
          </p>
        </div>
      </div>
    </Content>
  );
}
